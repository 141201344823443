import React from 'react';
import { BlogMain, BlogFlex, BlogCenter, BlogHeader, BlogPostInfo, BlogText, BlogSubHeader, TextHeader } from './../blogComps/style';
import { BlogGrid, DateText, TitleText, MainText } from '../../styles/blog';
import { TextButton } from '../../styles/services';
import { data } from '../homeComps/blog';
import * as Icon from 'react-feather';

const SingleBlogMainComp = () => {

    return(
        <>
            <BlogMain>
                <BlogFlex>
                    <BlogCenter>
                        <h3>A UX Case Study on Creating a Studious Environment for Students</h3>
                        <BlogPostInfo
                            style={{
                                justifyContent: 'center'
                            }}
                        >
                            <p>Daniel Adewale</p>
                            <p>Posted on 27th January 2021</p>
                        </BlogPostInfo>
                        <BlogHeader>
                            <img 
                                src='/images/blog1.png'
                                alt='blog'
                            />
                            <BlogSubHeader>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</BlogSubHeader>
                            <BlogText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore 
                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                                sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </BlogText>
                            <BlogSubHeader>enim ad minim veniam, quis nostrud.</BlogSubHeader>
                            <BlogText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore 
                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                                sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </BlogText>
                        </BlogHeader>
                        <TextHeader>Other News</TextHeader>
                        <BlogGrid>
                            {
                                data.slice(0, 3).map((item, index) => (
                                    <div key={index}>
                                        <img 
                                            src={item.img}
                                            alt='Blog'
                                        />
                                        <div>
                                            <DateText>{item.date}</DateText>
                                            <TitleText>{item.title}</TitleText>
                                            <MainText>{item.text}</MainText>
                                            <TextButton>
                                            <h4>Read More</h4>
                                            <i>
                                                <Icon.ArrowRight size={16} />
                                            </i>
                                        </TextButton>
                                        </div>
                                    </div>
                                ))
                            }
                        </BlogGrid>
                    </BlogCenter>
                </BlogFlex>
            </BlogMain>
        </>
    )
}

export default SingleBlogMainComp;