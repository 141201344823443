import styled from "styled-components";

export const MainWrap = styled.section`
    width:100%;
    display:flex;
    justify-content:center;
    gap:0;
    position:relative;
    z-index:10000;
`

export const LogoImage = styled.img`
    width:5rem;
`


interface PropsArgs {
    mobileBg?: string;
    mobileColor?: string;
}

export const MainHead = styled.div<PropsArgs>`
    width:90%;
    background: var(--primary-color);
    padding: 0.8rem 5% 0.8rem 5%;
    display:flex;
    align-items:Center;
    justify-content:space-between;
    margin: 0 auto;

    > div {
        display:flex;
        align-items:Center;
        justify-content:center;
        gap:100px;

        @media (max-width:900px){
            display:none;
        }
    }

    @media (max-width:900px){
        padding:0.8rem 5%;
        background: ${p => p.mobileBg || 'var(--primary-color)'};
        color: ${p => p.mobileColor || 'var(--main-bg)'};
    }
    
`;

export const MobileMenuController = styled.section<PropsArgs>`
    display:none;
    color: ${p => p.mobileColor || '#fff'};

    @media (max-width:900px){
        display:block;   
    }
`;

export const MobileModal = styled.div`
    position: fixed;
    width:86%;
    height:100%;
    top:0;
    left:0;
    background:#fff;
    padding:3rem 7%;

    > i {
        position:absolute;
        top:2rem;
        right:2rem;
    }
`