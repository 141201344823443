import React from 'react';
import { LeftSection, QualitiesFlex, QualitiesMain, RightSection, SideImage } from '../../styles/qualities';
import { IconHolder } from '../../styles/services';


interface PropsArgs {
    aboutRef?: any;
}

const Qualities = ({aboutRef} : PropsArgs) => {

    return(
        <>
            <QualitiesMain ref={aboutRef}>
                <h2>
                    Building the future of financial services accross Africa
                </h2>
                <p>In the context of Africa, SMEs are essentially contributing towards job creation and employment for a large populace and at Reef Financial Solutions we are powering these upward trends.</p>
                <QualitiesFlex>
                    <LeftSection>
                        <img 
                            src='images/woman.webp'
                            alt='Woman'
                        />
                        <div>
                            <h3>1,485+</h3>
                            <p>Trusted clients</p>
                        </div>
                        <SideImage 
                            src='images/woman2.webp'
                            alt='Woman'
                        />
                    </LeftSection>
                    <RightSection>
                        {
                            data.map((item, index) => (
                                <div key={index}>
                                    <IconHolder>
                                        <img 
                                            src={`icons/${item.icon}`}
                                            alt='Icon'
                                        />
                                    </IconHolder>
                                    <div>
                                        <h3>{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </RightSection>
                </QualitiesFlex>
            </QualitiesMain>
        </>
    )
}

export default Qualities;

const data = [
    {
        title: 'Microentreprises',
        text: 'From the young or old woman or man in the stall, selling cloth wares and jewelry and other body products to the one running in the small food store, we are providing simple yet fast payment solutions to drive and scale their businesses.',
        icon: 'partner.png'
    },
    {
        title: 'Small Enterprises',
        text: "For businesses dealing with multiple customers at a time and having several staff, like the grocery store at the corner of the street or the barber's shop at the junction we are making their payment solution seamless, reducing any eventuality of long queues and wait times.",
        icon: 'system.png'
    },
    {
        title: 'Medium-sized enterprises',
        text: 'Including tech startups and service business with up to 50 staff or more or even less, we are providing robust financial solutions to help drive their business operations and scale. From KYC/AML to corporate services like mass/salary payouts to seamless payments receivable methods.',
        icon: 'chart.png'
    }
]