import styled from "styled-components";

export const ProductsMain = styled.div`
    margin: 3rem auto 0 auto;
    padding: 4rem 10%;
    text-align:center;
    background: #F9FBFF;

    > h2 {
        font-weight: 500;
        font-size: 34px;
    }

    > p {
        padding:0 17%;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
    }

    @media (max-width:900px){
        margin:0 auto 0 auto;
        padding:4rem 5%;

        > h2 {
            font-size:25px;
        }

        > p {
            padding:0 5%;
        }
    }
`

export const FlexedData = styled.div`
    gap:60px;
    margin:5rem auto 0 auto;

    img {
        width:28rem;
        cursor:pointer;
    }

    img:hover {
        opacity:0.6;
        transition:0.5s;
    }

    @media (max-width:900px){
        gap:0;

        img {
            width:100%;
        }
    }
`

export const DataSection = styled.section`
    margin:5rem 0;
    padding:3rem 7rem;
    background: var(--primary-color);
    display:flex;
    align-items:Center;
    text-align:Center;
    justify-content:center;
    gap:100px;
    color: #fff;

    > div {
        > h3 {
            font-size:30px;
            font-weight:700
        }
    }

    @media (max-width:900px){
        padding:3rem 1rem;
        flex-wrap:wrap;
        gap:30px;

        > div {
            width:45%;

            > h3 {
                font-size:25px;
            }

            > p {
                position:relative;
                top:-1rem;
                font-size:14px;
            }
        }
    }
`

interface CarouselProps {
    bg: string;
}

export const CarouselBtnWrap = styled.div`
    position:absolute;
    top:0rem;
    right:0%;
    display:flex;
    gap:30px;
    background: rgba(0, 0, 0, 0.05);
    border-radius:30px;
    padding:0.4rem 1rem;
    display:none;

    @media (max-width:900px){
        display:flex;
    }
`

export const CarouselBtn = styled.div<CarouselProps>`
    width:3rem;
    height:3rem;
    border-radius:10rem;
    background: ${p => p.bg || 'transparent'};
    display:flex;
    align-items:Center;
    justify-content:center;
    cursor:pointer;

    :hover {
        opacity:0.8;
    }
`