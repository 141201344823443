import React from 'react';
import { CopyRightText, FooterAbout, FooterFlex, FooterMain, FooterNavigations, FooterSocialWrap, RoundedSheet } from '../../styles/footer';
import * as Icon from 'react-feather';
import {useNavigate} from 'react-router-dom';

interface PropsArgs {
    scrollToElement?: any;
    scrollToAbout?: any;
}

const Footer = ({scrollToElement, scrollToAbout} : PropsArgs) => {

    const navigate = useNavigate();
    const date = new Date();
    let year = date.getFullYear();

    const handleToContact = () => {
        navigate('/contact');
    }

    const quickLinks = [
        {
            name: 'About',
            url: '',
            action: scrollToAbout
        },
        {
            name: 'Products',
            url: '',
            action: scrollToElement
        },
        {
            name: 'Contacts',
            url: '/contact',
            action: handleToContact
        },
    ]

    return(
        <>
            <FooterMain>
                <FooterFlex>
                    <FooterAbout>
                        <h3>About Us</h3>
                        <p>At Reef we are creating a new Africa, one in which anyone can start and run a small business in hours rather than months. We are starting by building technology . . . </p>
                        <FooterSocialWrap>
                            <div><Icon.Facebook size={17} /></div>
                            <div><Icon.Linkedin size={17}  /></div>
                            <div><Icon.Instagram size={17}  /></div>
                            <div><Icon.Twitter size={17}  /></div>
                        </FooterSocialWrap>
                    </FooterAbout>
                    <FooterNavigations>
                        <div>
                            <h3>Quick Links</h3>

                            {
                                quickLinks.map((item, index) => (
                                    <p 
                                        key={index}
                                        onClick={() => {
                                            item.action();
                                        }}
                                    >{item.name}</p>
                                ))
                            }
                        </div>
                        <div>
                            <h3>Useful Links</h3>
                            {
                                usefulLinks.map((item, index) => (
                                    <p key={index}>{item.name}</p>
                                ))
                            }
                        </div>
                        <div>
                            <h3>Download our products</h3>
                            <p>We are building the best products for all businesses in Africa to grow and scale. To begin to be a part of this successes, go ahead and download our apps now and begin to integrate seamlessly all of the great functionalities we have to offer.</p>
                            <div>
                                <img 
                                    src='/images/mongoro-logo.webp'
                                    alt='Mongoro'
                                />
                                <img 
                                    src='/images/miklogo.webp'
                                    alt='MyIdentiKey'
                                />
                            </div>
                        </div>
                    </FooterNavigations>
                </FooterFlex>
                <CopyRightText>
                    © {year}, Reef Financial Solutions Ltd | All Rights Reserved 
                </CopyRightText>
                <RoundedSheet></RoundedSheet>
            </FooterMain>
        </>
    )
}

export default Footer;

const usefulLinks = [
    {
        name: 'Privacy Policy',
        url: '',
        action: ''
    },
    {
        name: 'Terms and Conditions',
        url: '',
        action: ''
    },
    {
        name: 'Disclaimer',
        url: '',
        action: ''
    },
    {
        name: 'Support',
        url: '',
        action: ''
    },
    {
        name: 'FAQs',
        url: '',
        action: ''
    },
]