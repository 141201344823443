import React, { useState } from 'react';
import { ContactFlex, ContactLeft, ContactMain, ContactRight, ContactIconHolder, ContactInputFlex, SmallInput, BigInput } from '../../styles/contact';
import { Button } from '../../styles/reusable';
import * as Icon from 'react-feather';
import emailjs from 'emailjs-com';
import { ClipLoader } from 'react-spinners';
import Alert from '../reusable/alert';

const ContactMainComp = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [message, setMessage] = useState('');
    const [onProcess, setOnProcess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<any>('')

    const submit = (e:any) => {
        e.preventDefault();
        setOnProcess(true);
        setTimeout(() =>{
            setOnProcess(false);
        }, 30000)
        if (fullName && subject && email && message) {
            const serviceId:any = process.env.REACT_APP_EMAILJS_SERVICE_ID;
            const templateId:any = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
            const userId:any = process.env.REACT_APP_EMAILJS_USER_ID;
            const templateParams = {
                fullName,
                email,
                subject,
                message,
                compEmail: process.env.REACT_APP_EMAILJS_COMP_EMAIL,
                phone: phoneNo
            };

            emailjs.send(serviceId, templateId, templateParams, userId)
                .then(response => {
                    setOnProcess(false);
                    if (response.status === 200){
                        setSuccess(true);
                        setFullName('');
                        setEmail('')
                        setPhoneNo('')
                        setSubject('')
                        setMessage('')
                    }
                })
                .then(error => {
                    setOnProcess(false);
                    setError(error);
                    setTimeout(() => {
                        setError('');
                    }, 4000)
                });
        } else {
            setOnProcess(false);
            setError('Please fill in all fields.');
            setTimeout(() => {
                setError('')
            }, 4000)
        }
    }

    return(
        <>
            {
                success ? 
                    <Alert 
                        closeFunc={() => setSuccess(false)}
                        img='./icons/success.png'
                        message='Message sent!'
                        miniMessage='Thank you for sending this mail, you will recieve a feedback shortly'
                    />

                    : null
            }

            {
                error && error !== '' ? 
                    <Alert 
                        closeFunc={() => setError('')}
                        img='./icons/warning.png'
                        message='An error occured!'
                        miniMessage={error}
                    />

                    : null
            }
            <ContactMain>
                <ContactFlex>
                    <ContactLeft>
                        <h3>Get Closer with us</h3>
                        <p>We provides a seamless and convenient way of connecting people's financial accounts to an app with easy and secure access Please feel free to contact us and we will get back to you as soon as we can</p>
                        <div>
                            <div>
                                <ContactIconHolder>
                                    <Icon.MapPin size={20} color='#fff' />
                                </ContactIconHolder>
                                <section>
                                    <p>21 Blantyre Crescent, Wuse 2. Abuja</p>
                                </section>
                            </div>
                            <div>
                                <ContactIconHolder>
                                    <Icon.Clock size={20} color='#fff' />
                                </ContactIconHolder>
                                <section>
                                    <p>9AM - 8PM</p>
                                </section>
                            </div>
                            <div>
                                <ContactIconHolder>
                                    <Icon.Phone size={20} color='#fff' />
                                </ContactIconHolder>
                                <section>
                                    <p>+2348033550170</p>
                                </section>
                            </div>
                            <div>
                                <ContactIconHolder>
                                    <Icon.Mail size={20} color='#fff' />
                                </ContactIconHolder>
                                <section>
                                    <p>{process.env.REACT_APP_EMAILJS_COMP_EMAIL}</p>
                                </section>
                            </div>
                        </div>
                    </ContactLeft>
                    <ContactRight>
                        <form onSubmit={(e) => submit(e)}>
                        <ContactInputFlex >
                            <SmallInput>
                                <label>Name*</label>
                                <input 
                                    placeholder='Name' 
                                    required={true}
                                    value={fullName}
                                    onChange={e => setFullName(e.target.value)}
                                />
                            </SmallInput>
                            <SmallInput>
                                <label>Email*</label>
                                <input 
                                    placeholder='Email' 
                                    type='email'
                                    value={email}
                                    required={true}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </SmallInput>
                            <SmallInput>
                                <label>Subject*</label>
                                <input 
                                    placeholder='Subject' 
                                    required={true}
                                    value={subject}
                                    onChange={e => setSubject(e.target.value)}
                                />
                            </SmallInput>
                            <SmallInput>
                                <label>Phone Number</label>
                                <input 
                                    placeholder='Phone number' 
                                    type='number'
                                    value={phoneNo}
                                    onChange={e => setPhoneNo(e.target.value)}
                                />
                            </SmallInput>
                            <BigInput>
                                <label>Message*</label>
                                <textarea 
                                    placeholder='Leave your message'
                                    onChange={e => setMessage(e.target.value)}
                                    value={message}
                                    required={true}
                                ></textarea>
                            </BigInput>
                        </ContactInputFlex>
                        <Button
                            bg='var(--primary-color)'
                            color='#fff'
                            type='submit'
                            disabled={onProcess}
                            style={
                                onProcess ? {cursor: 'not-allowed'} : {}
                            }
                        >
                            {
                                onProcess ? <ClipLoader size={23} color='#fff' />
                                :
                                'Send Message'
                            }
                        </Button>
                        </form>
                    </ContactRight>
                </ContactFlex>
            </ContactMain>
        </>
    )
}

export default ContactMainComp;