import React, { useEffect } from 'react';
import AppHeader from '../components/reusable/header';
import Footer from '../components/reusable/footer';
import ContactMainComp from '../components/contactComps/mainContent';

const Contact = () => {
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return(
        <>
            <div className="main-widget">
                <AppHeader 
                    mobileColor='var(--primary-color)'
                    mobileBg='#fff'
                />
                <ContactMainComp />
                <Footer />
            </div>
        </>
    )
} 

export default Contact;