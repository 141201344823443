import React, { useState } from 'react';
import { MainWrap, MainHead, LogoImage, MobileMenuController, MobileModal } from '../../styles/headerStyle';
import * as Icon from 'react-feather';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';

interface PropsArgs {
    mobileBg?: string;
    mobileColor?: string;
    scrollToElement?: any;
}

const AppHeader = ({mobileBg, mobileColor, scrollToElement} : PropsArgs) => {

    const location = useLocation();
    const current = location.pathname;

    const homeLinks = ['/'];
    const homeActive = homeLinks.includes(current);
    const contactLinks = ['/contact'];
    const contactActive = contactLinks.includes(current);
    const [showMobile, setShowMobile] = useState(false);

    return(
        <>
            <MainWrap>
                <MainHead mobileBg={mobileBg} mobileColor={mobileColor}>
                    <Link to='/'>
                        <LogoImage 
                            src='/images/logo.png'
                            alt='Reef'
                        />
                    </Link>
                    <div>
                        <p className={'navigation-text'}>
                            <NavLink to='/'>
                                Home
                            </NavLink>
                        </p>
                        <p 
                            className='navigation-text'
                            onClick={() => scrollToElement()}
                        >Products</p>
                        <p className={' navigation-text'}>
                            <NavLink to='/contact'>
                                Contact Us
                            </NavLink>
                        </p>
                    </div>
                    <span></span>
                    <MobileMenuController
                        mobileColor={mobileColor}
                    >
                        <i>
                            <Icon.Menu onClick={() => setShowMobile(true)}  />
                        </i>
                    </MobileMenuController>
                </MainHead>
                {
                    showMobile ?
                
                        <MobileModal>
                            <i><Icon.X onClick={() => setShowMobile(false)} /></i>
                            <Link to='/'>
                                <LogoImage 
                                    src='/images/logo.png'
                                    alt='Reef'
                                />
                            </Link>
                            <div>
                                <p className={'navigation-text'}>
                                    <NavLink to='/'>
                                        Home
                                    </NavLink>
                                </p>
                                <p 
                                    className='navigation-text'
                                    onClick={() => {
                                        scrollToElement();
                                        setShowMobile(false)
                                    }}
                                >Products</p>
                                <p className={' navigation-text'}>
                                    <NavLink to='/contact'>
                                        Contact Us
                                    </NavLink>
                                </p>
                            </div>
                        </MobileModal>

                        : null
                }
            </MainWrap>
        </>
    )
}

export default AppHeader;