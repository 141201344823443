import styled from "styled-components";

export const QualitiesMain = styled.div`
    margin: 0rem auto 0 auto;
    padding: 4rem 10%;
    text-align:center;

    > h2 {
        font-weight: 700;
        font-size: 30px;
        font-family: var(--generic-font);
        padding:0 20%;
    }

    > p {
        padding:0 17%;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
    }

    @media (max-width:900px){
        padding:2rem 5%;

        > h2 {
            font-size:25px;
            padding:0 5%;
        }

        > p {
            padding:0 5%;
        }
    }
`

export const QualitiesFlex = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin: 4rem 0 2rem 0;

    @media (max-width:900px){
        flex-direction:column;
    }
`

export const LeftSection = styled.div`
    width:45%;
    position:relative;

    > img:first-child {
        width:100%;
    }

    > div {
        z-index:10000;
        position:absolute;
        bottom:1rem;
        left: 7rem;
        width:150px;
        height:150px;
        border-radius:10rem;
        background:var(--primary-color);
        display:flex;
        flex-direction:column;
        align-items:Center;
        justify-content:Center;
        gap:0;
        color: var(--main-bg);
        line-height:0;

        > h3 {
            font-weight:700;
            font-size:25px;
        }

        > p {
            font-weight: 600;
            font-size: 14px;
            line-height: 23px;
            position:relative;
            top: -0.5rem;
        }
    }

    @media (max-width:900px){
        width:90%;
        margin: 0 auto;

        > div {
            width: 90px;
            height:90px;
            left:4rem;

            > h3 {
                font-size:12px;
            }

            > p {
                font-size:10px;
                top:0
            }
        }
    }
`

export const SideImage = styled.img`
    position:absolute;
    width:17rem;
    bottom:3rem;
    left:-5rem;

    @media (max-width:900px){
        width:8rem;
        left:-1rem;
        bottom:1rem;
    }
`

export const RightSection = styled.div`
    width:45%;

    > div {
        display:flex;
        // align-items:Center;
        justify-content:flex-start;
        margin:2rem 0;
        gap:20px;

        > div:first-child{
            margin:2.5rem 0 0 0;
        }

        > div:nth-child(2) {
            width:70%;
            text-align:left;

            > h3 {
                font-weight: 500;
                font-size: 25px;
                line-height: 32px;
                margin:0.5rem 0 0 0;
            }

            > p {
                font-weight: 400;
                font-size: 14px;
                line-height: 25px;
                position:relative;
                top:-0.5rem;
            }
        }
    }

    @media (max-width:900px){
        width:100%;
        margin:3rem 0 0 0;

        > div {
            flex-direction:column;
            align-items:center;

            > div:first-child{
                margin:0;
            }

            > div:nth-child(2){
                width:100%;
                text-align:center;
            }

        }
    }
`