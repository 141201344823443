import styled from "styled-components";

export const BlogMain = styled.section`
    position:relative;
    height:auto;

    ::before {
        content: '';
        width:100%;
        height:16rem;
        background: url('aesthetic/hero.svg');
        background-size:cover;
        background-position;
        position:absolute;
        top:0;
        left:0;
    }
`;

export const BlogFlex = styled.div`
    padding:3rem 10% 3rem 10%;
    display:flex;
    align-items:center;
    justify-content:space-between;

    @media (max-width:900px){
        flex-direction:column;
        padding:3rem 3%;
    }
`

export const BlogCenter = styled.div`
    width:100%;
    z-index:1000;

    > h3 {
        font-weight: 700;
        font-size: 40px;
        font-family: var(--generic-font);
        text-align:center;

        @media (max-width:900px){
            font-size:25px;
        }
    }

    > p {
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
        position:relative;
        text-align:center;
        width:80%;
        margin:0 auto;
        top: -2rem;

        @media (max-width:900px){
            font-size:14px;
            top:-1rem;
        }
    }

    @media (max-width:900px){
        width: 100%;

        > P {
            width:100%;
        }
    }
`;

export const BlogHeader = styled.div`
    position:relative;
    margin:3rem 0 0 0;

    > img {
        width:100%;
    }

    > h3 {
        font-weight: 700;
        font-size: 40px;
        font-family: var(--generic-font);
        text-align:left;

        @media (max-width:900px){
            font-size:25px;
        }
    }
`

export const TextHeader = styled.h3`
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #282938;
    margin:10rem 0 0 0;
`

export const BlogPostInfo = styled.div`
    display:flex;
    align-items:Center;
    flex-wrap:wrap;
    gap:10px;

    > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #282938;
        opacity: 0.87;
    }
` 

export const BlogSubHeader = styled.h2`
    font-weight: 700;
    font-size: 38px;
    line-height: 56px;
    margin:3rem 0;
    color: #282938;

    @media (max-width:728px){
        font-size:25px;
        line-height:40px;
    }
`

export const BlogText = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align:left;
    color: #282938;
    opacity: 0.7;
`

export const ReadMore = styled.h4`
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #2405F2;
    opacity: 0.7;
`