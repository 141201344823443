import React from 'react';
import './styles/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Contact from './pages/contacts';
import Blog from './pages/blog';
import SingleBlog from './pages/singleBlog';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/blogs' element={<Blog />} />
          <Route path='/blog/:id' element={<SingleBlog />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
