import styled from "styled-components";

export const ServiceMain = styled.div`
    margin: 15rem auto 0 auto;
    padding: 4rem 5%;
    text-align:center;

    > h2 {
        font-weight: 500;
        font-size: 34px;
    }

    > p {
        padding:0 17%;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
    }

    @media (min-width:600px){
        margin: 35rem auto 0 auto;
    }

    @media (min-width:900px){
        margin: 15rem auto 0 auto;
    }

    @media (max-width:600px){
        margin: 35rem auto 0 auto;
        padding: 4rem 5%;

        > h2 {
            font-size:25px;
        }

        > p {
            padding:0 5%;
        }
    }
`

export const FlexedData = styled.div`
    display:flex;
    justify-content:space-between;
    gap:60px;
    margin:5rem 0 0 0;

    > div:nth-child(2){
        margin-top:1.2rem;
    }

    > div {
        flex: 1;
        padding:1rem;
        display:flex;
        flex-direction:column;
        align-items:center;

        > h3 {
            font-weight: 500;
            font-size: 25px;
            line-height: 32px;
        }

        > p {
            font-weight: 300;
            font-size: 14px;
            line-height: 30px;
        }
    }

    @media (max-width:900px){
        flex-direction:column;
    }
`

export const IconHolder = styled.div`
    width:80px;
    height:80px;
    border-radius:10rem;
    background: var(--primary-color);
    display:flex;
    align-items:center;
    justify-content:center;

    > img {
        width:2rem;
    }
`

export const TextButton = styled.div`
    display:flex;
    align-items:center;
    gap:10px;
    color: var(--primary-color);

    > h3 {
        font-weight:600;
        font-size:18px;
    }

    > h4 {
        font-weight:500;
        font-size:16px;
    }

    > i {
        margin: 0.4rem 0 0 0;
    }
`