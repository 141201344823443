import styled from 'styled-components';

export const BlogMain = styled.div`
    margin: 3rem auto 0 auto;
    padding: 4rem 10%;

    > h2 {
        font-weight: 500;
        font-size: 34px;
        text-align:Center;
    }

    > p {
        padding:0 17%;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
        text-align:Center;
    }

    @media (max-width:900px){
        padding:2rem 5%;

        > h2 {
            font-size:25px;
        }

        > p {
            padding:0 5%;
        }
    }
`

export const BlogGrid = styled.div`
    display:grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    gap:30px;
    margin: 3rem 0 0 0;

    > div {
        position:relative;
        color: #282938;

        > img {
            width:100%;
            height:256px;
            object-fit:cover;
        }
    }

    @media (max-width:900px){
        grid-template-columns: repeat(1, 1fr);
    }
`

export const DateText = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    opacity:0.7;
`

export const TitleText = styled.h3`
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    overflow:hidden;
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;
    text-overflow:ellipsis;
`

export const MainText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    opacity:0.7;
    overflow:hidden;
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;
    text-overflow:ellipsis;
`