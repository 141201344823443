import React from 'react';
import { BlogMain, BlogFlex, BlogCenter, BlogHeader, BlogPostInfo, BlogText, ReadMore, TextHeader } from './style';
import { BlogGrid, DateText, TitleText, MainText } from '../../styles/blog';
import { TextButton } from '../../styles/services';
import { data } from '../homeComps/blog';
import * as Icon from 'react-feather';

const BlogMainComp = () => {

    return(
        <>
            <BlogMain>
                <BlogFlex>
                    <BlogCenter>
                        <h3>Our Blog</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua tenimad minim veniam quis nostrud exercitation.</p>
                        <BlogHeader>
                            <img 
                                src='/images/blog1.png'
                                alt='blog'
                            />
                            <h3>A UX Case Study on Creating a Studious Environment for Students</h3>
                            <BlogPostInfo>
                                <p>Daniel Adewale</p>
                                <p>Posted on 27th January 2021</p>
                            </BlogPostInfo>
                            <BlogText>
                                Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon of the sea to the level of the spectator on a hillside.
                            </BlogText>
                            <ReadMore>Read More</ReadMore>
                        </BlogHeader>
                        <TextHeader>Other News</TextHeader>
                        <BlogGrid>
                            {
                                data.map((item, index) => (
                                    <div key={index}>
                                        <img 
                                            src={item.img}
                                            alt='Blog'
                                        />
                                        <div>
                                            <DateText>{item.date}</DateText>
                                            <TitleText>{item.title}</TitleText>
                                            <MainText>{item.text}</MainText>
                                            <TextButton>
                                            <h4>Read More</h4>
                                            <i>
                                                <Icon.ArrowRight size={16} />
                                            </i>
                                        </TextButton>
                                        </div>
                                    </div>
                                ))
                            }
                        </BlogGrid>
                    </BlogCenter>
                </BlogFlex>
            </BlogMain>
        </>
    )
}

export default BlogMainComp;