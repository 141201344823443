import React, { useRef } from 'react';
import HomeHero from '../components/homeComps/homeHero';
import Services from '../components/homeComps/services';
import Qualities from '../components/homeComps/qualities';
import Products from '../components/homeComps/products';
import BlogSection from '../components/homeComps/blog';
import Footer from '../components/reusable/footer';

const Home = () => {

    const productRef:any = useRef([]);
    const aboutRef:any = useRef([]);
    const scrollToElement:any = () => productRef.current.scrollIntoView();
    const scrollToAbout:any = () => aboutRef.current.scrollIntoView();

    return(
        <>
            <div className="main-widget">
                <HomeHero 
                    scrollToElement={scrollToElement} 
                    scrollToAbout={scrollToAbout}
                />
                <Services />
                <Qualities aboutRef={aboutRef} />
                <Products productRef={productRef} />
                <BlogSection />
                <Footer 
                    scrollToAbout={scrollToAbout} 
                    scrollToElement={scrollToElement} 
                />
            </div>
        </>
    )
} 

export default Home;