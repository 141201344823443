import React, { useState } from 'react';
import { HeroHeaderWrap, HeroMain, CenterHero, TextHeader, SupplText, HangingSection, TextField} from './styles';
import { Button } from '../../styles/reusable';
import AppHeader from '../reusable/header';
import { TypeAnimation } from 'react-type-animation';
import Alert from '../reusable/alert';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

interface PropsArgs{
    scrollToElement?: any;
    scrollToAbout?: any;
}

const HomeHero = ({scrollToElement, scrollToAbout} : PropsArgs) => {

    const width = window.innerWidth;

    const [email, setEmail] = useState('');
    const [onProcess, setOnProcess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('')

    const body = {
        email
    }

    const handleJoin = (e:any) => {
        e.preventDefault();
        setOnProcess(true)
        axios.post(`${process.env.REACT_APP_API_URL}/reef_waitlist/create`, body).then((res) => {
            setSuccess(true);
            setOnProcess(false)
            setEmail('');
        }).catch((err) => {
            setError(err.response.data.msg);
            setOnProcess(false)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    return(
        <>
            {
                success ? 
                    <Alert 
                        closeFunc={() => setSuccess(false)}
                        img='./icons/success.png'
                        message='Congratulations!'
                        miniMessage='You have successfully joined our wailist. Wait for more updates from us!'
                    />

                    : null
            }

            {
                error && error !== '' ? 
                    <Alert 
                        closeFunc={() => setError('')}
                        img='./icons/warning.png'
                        message='An error occured!'
                        miniMessage={error}
                    />

                    : null
            }

            <HeroHeaderWrap>
                <AppHeader scrollToElement={scrollToElement} />
                <HeroMain>
                    <CenterHero>
                        <div>
                            <TextHeader>
                                Expand your

                                {
                                    width < 900  ?
                                        <br />
                                    : null
                                }
                            <TypeAnimation
                                // Same String at the start will only be typed once, initially
                                sequence={[
                                    ' Business',
                                    1000,
                                    ' Payments',
                                    1000,
                                    ' Sales Opportunities',
                                    1000,
                                    ' Sales Revenue!',
                                    1000,
                                ]}
                                speed={40}
                                wrapper="span"
                                repeat={Infinity}
                            />
                            </TextHeader>
                            <SupplText>
                                Seamless financial technological and identity management solutions to fast-track revenue collections and business growth. Reef is an all-inclusive financial solutions company with the intent to make every business in Africa thrive and scale.
                            </SupplText>
                            <Button
                                bg='var(--main-bg)'
                                color='var(--text-black)'
                                padding='0.9rem 3rem'
                                onClick={() => scrollToAbout()}
                            >
                                About Us
                            </Button>
                        </div>
                    </CenterHero>
                </HeroMain>
                <HangingSection>
                    <img 
                        src='images/hero-man.webp'
                        alt='Man'
                    />
                    <form onSubmit={(e) => handleJoin(e)}>
                        <h3>Join the waitlist</h3>
                        <p>Be the first to begin deploying our extensive financial solutions to grow your business. We only need your email address for you to be added to our waitlist and we'll get back.</p>
                        <TextField>
                            <input 
                                type='email'
                                value={email}
                                placeholder='Your email'
                                required={true}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                bg='var(--primary-color)'
                                color='var(--main-bg)'
                                type='submit'
                            >
                                {onProcess ? <ClipLoader size={14} color='#fff' /> : 'Join'}
                            </Button>
                        </TextField>
                    </form>
                </HangingSection>
            </HeroHeaderWrap>
        </>
    )
}

export default HomeHero;

export const CheckItems: any = [
    {
        name: 'Online Payment'
    },
    {
        name: 'Platform Support'
    },
    {
        name: 'Secure Transaction'
    },
    {
        name: 'Responsive Web App'
    },
    {
        name: 'Group Payments'
    },
    {
        name: 'KYC Protection'
    },

]