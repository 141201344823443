import React from 'react';
import * as Icon from 'react-feather';
import { FlexedData, IconHolder, ServiceMain, TextButton } from '../../styles/services';

const Services = () => {
    return(
        <>
            <ServiceMain>
                <h2>
                    Our Services
                </h2>
                <p>At Reef we are creating a new Africa, one in which anyone can start and run a small business in hours rather than months. We are starting by building technology that allows SMEs to accept whatever forms of payment the consumer offers - card payments from mPOS, bank transfers, QR code, USSD payment, near field sound payment, cryptocurrencies (with the attendant on/off-ramp infra.)</p>
                <FlexedData>
                    {
                        data.map((item, index) => (
                            <div key={index}>
                                <IconHolder>
                                    <img 
                                        src={`icons/${item.icon}`}
                                        alt='Icon'
                                    />
                                </IconHolder>
                                
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                                <TextButton>
                                    <h4>Read More</h4>
                                    <i>
                                        <Icon.ArrowRight />
                                    </i>
                                </TextButton>
                            </div>
                        ))
                    }
                </FlexedData>
            </ServiceMain>
        </>
    )
}

export default Services;

const data = [
    {
        title: 'Receivables: Get paid on time, every time.',
        text: 'To thrive, the way you process your payments matters. To keep your business thriving, you need a fast, reliable method for collecting payments and reconciling invoices. . .',
        icon: 'lock.png'
    },
    {
        title: 'Payment: Simple, secure and fast payments',
        text: 'Paying local suppliers to speed up your business processes? Sending multiple payments to employees and service provider?  .  . .',
        icon: 'user.png'
    },
    {
        title: 'KYC/AML: Know your customers/Anti-Money Laundry',
        text: 'Reef Financial Solutions will NOT do business with anyone suspected of or directly involved in Money Laundering, or where funds have been sourced by any illegal activity.',
        icon: 'money.png'
    }
]