import React from 'react';
import { BlogGrid, BlogMain, DateText, MainText, TitleText } from '../../styles/blog';
import { TextButton } from '../../styles/services';
import * as Icon from 'react-feather';

const BlogSection = () => {
    return(
        <>
            <BlogMain>
                <h2>
                    What we’re Reading
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua tenimad minim veniam quis nostrud exercitation.</p>

                <BlogGrid>
                    {
                        data.slice(0, 3).map((item, index) => (
                            <div key={index}>
                                <img 
                                    src={item.img}
                                    alt='Blog'
                                />
                                <div>
                                    <DateText>{item.date}</DateText>
                                    <TitleText>{item.title}</TitleText>
                                    <MainText>{item.text}</MainText>
                                    <TextButton>
                                    <h4>Read More</h4>
                                    <i>
                                        <Icon.ArrowRight size={16} />
                                    </i>
                                </TextButton>
                                </div>
                            </div>
                        ))
                    }
                </BlogGrid>
            </BlogMain>
        </>
    )
}

export default BlogSection;

export const data = [
    {
        id: 1,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        text: 'Just some testing See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract',
        date: '27 Jan, 2022',
        img: '/images/people.webp'
    },
    {
        id: 2,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        text: 'See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract',
        date: '27 Jan, 2022',
        img: '/images/woman3.webp'
    },
    {
        id: 3,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        text: 'See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract',
        date: '27 Jan, 2022',
        img: '/images/devices.webp'
    },
    {
        id: 4,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        text: 'See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract',
        date: '27 Jan, 2022',
        img: '/images/devices.webp'
    },
    {
        id: 5,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        text: 'See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract',
        date: '27 Jan, 2022',
        img: '/images/woman3.webp'
    },
    {
        id: 6,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        text: 'Just some testing See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract',
        date: '27 Jan, 2022',
        img: '/images/people.webp'
    },
]