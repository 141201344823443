import React, { useEffect } from 'react';
import AppHeader from '../components/reusable/header';
import Footer from '../components/reusable/footer';
import SingleBlogMainComp from '../components/singleBlogComps/mainContent';

const SingleBlog = () => {
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return(
        <>
            <div className="main-widget">
                <AppHeader 
                    mobileColor='var(--primary-color)'
                    mobileBg='#fff'
                />
                <SingleBlogMainComp />
                <Footer />
            </div>
        </>
    )
} 

export default SingleBlog;