import React, { useEffect, useState } from 'react';
import { CarouselBtn, ProductsMain, FlexedData, DataSection, CarouselBtnWrap } from '../../styles/products';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import * as Icon from 'react-feather';


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

interface PropsArgs {
    productRef?: any;
}

const Products = ({productRef} : PropsArgs) => {
    return(
        <>
            <ProductsMain ref={productRef}>
                <h2>
                    Our Products
                </h2>
                <p>Reaching African SMEs with the best payment infrastructure remain the backbone of our core responsibility, while putting security in mind.</p>

                <FlexedData>
                    <Carousel 
                        responsive={responsive}
                        arrows={false}
                        infinite={true}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {
                            products.map((item, index) => (
                                <a 
                                    href={item.link} 
                                    target='_blank' 
                                    rel='noreferrer' 
                                    key={index}
                                >
                                    <img
                                        src={item.img}
                                        alt={item.name}
                                    />
                                </a>
                                
                            ))
                        }
                    </Carousel>
                </FlexedData>
            </ProductsMain>
            <DataSection>
                {
                    data.map((item, index) => (
                        <div key={index}>
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                        </div>
                    ))
                }
            </DataSection>
        </>
    )
}

export default Products;

interface ButtonProps {
    next?: any;
    previous?: any;
    goToSlide?: any;
    rest?: any;
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest } : ButtonProps) => {
    const { carouselState: { currentSlide } } : any = rest;

    return (
      <CarouselBtnWrap>
        <CarouselBtn 
            className={currentSlide === 0 ? 'disable' : ''} 
            onClick={() => previous()}
            bg={currentSlide === 0 ? 'transparent' : 'var(--primary-color)'}
        >
            <Icon.ChevronLeft
                color={currentSlide === 0 ? '#062403' : '#fff'}
            />
        </CarouselBtn>
        <CarouselBtn 
            onClick={() => next()}
            bg={'var(--primary-color)'}
        >
            <Icon.ChevronRight 
                color={'#fff'}
            />
        </CarouselBtn>
      </CarouselBtnWrap>
    );
  };

const products = [
    {
        img: '/images/mongoro.webp',
        name: 'Mongoro',
        link: 'https://mongoro.com'
    },
    {
        img: '/images/myIdentiKey.webp',
        name: 'MyIdentiKey',
        link: 'https://myidentikey.com'
    },
]

const data = [
    {
        title: '10+',
        text: 'Years of experience'
    },
    {
        title: '3,452+',
        text: 'Total Transactions'
    },
    {
        title: '652+',
        text: 'Active Users'
    },
    {
        title: '592 +',
        text: 'Positive Reviews'
    }
]