import styled from "styled-components";

export const ContactMain = styled.section`
    position:relative;
    height:auto;

    ::before {
        content: '';
        width:100%;
        height:16rem;
        background: url('aesthetic/hero.svg');
        background-size:cover;
        background-position;
        position:absolute;
        top:0;
        left:0;
    }
`;

export const ContactFlex = styled.div`
    padding:6rem 10% 3rem 10%;
    display:flex;
    align-items:center;
    justify-content:space-between;

    @media (max-width:900px){
        flex-direction:column;
        padding:3rem 3%;
    }
`

export const ContactLeft = styled.div`
    width:40%;
    z-index:1000;

    > h3 {
        font-weight: 700;
        font-size: 40px;
        font-family: var(--generic-font);

        @media (max-width:900px){
            font-size:25px;
        }
    }

    > p {
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
        position:relative;
        top: -2rem;

        @media (max-width:900px){
            font-size:14px;
            top:-1rem;
        }
    }

    > div {
        background: var(--main-bg);
        box-shadow: 0px 4px 32px rgba(0, 106, 255, 0.1);
        border-radius: 10px;
        padding:2rem;

        > div {
            display:flex;
            align-items:center;
            gap:20px;
            margin: 0 0 1rem 0;

            p {
                font-weight: 300;
                font-size: 14px;
                line-height: 30px;
            }

            > section {
                width:75%;
            }
        }

        @media (max-width:900px){
            padding:2rem 5%;

            > div {
                > section {
                    width:70%;
                }
            }
            
        }
    }

    @media (max-width:900px){
        width: 100%;
    }
`

export const ContactRight = styled.div`
    width:50%;
    background: #FFFFFF;
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
    border-radius: 10px;
    z-index:1000;
    padding:2rem;

    button {
        width:10rem;
        display:block;
        margin:2rem auto;
    }

    @media (max-width:900px){
        width: 90%;
        padding:2rem 5%;
        margin:3rem 0 0 0;
    }
`

export const ContactIconHolder = styled.div`
    width:60px;
    height:60px;
    display:flex;
    align-items:Center;
    justify-content:center;
    border-radius:10rem;
    background: var(--primary-color);
`

export const ContactInputFlex = styled.div`
    display:flex;
    align-items:Center;
    justify-content:space-between;
    flex-wrap:wrap;
`

export const SmallInput = styled.div`
    width:47%;
    margin:0 0 2rem 0;

    > input {
        width:94%;
        padding:0.7rem 3%;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        margin:0.5rem 0 0 0;
    }
    
    label {
        font-size:14px;
        font-weight: 500;
        margin: 0 0 0 1rem;
    }

    @media (max-width:900px){
        width:100%;
        margin:0 0 1.3rem 0;

        label {
            margin:0;
        }
    }
`

export const BigInput = styled.div`
    width:100%;
    margin:0 0 2rem 0;

    > textarea {
        height: 10rem;
        width:94%;
        padding:0.7rem 3%;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        margin:0.5rem 0 0 0;
        resize:none;
    }
    
    label {
        font-size:14px;
        font-weight: 500;
        margin: 0 0 0 1rem;
    }

    @media (max-width:900px){
        width:100%;
        margin:0 0 1.3rem 0;

        label {
            margin:0;
        }
    }
`