import styled from "styled-components";

export const HeroHeaderWrap = styled.section`
    height:100vh;
    background-image: url('aesthetic/hero-bg.svg');
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    position:relative;

    @media (min-width:2000px){
        padding:0 5%;
        height:50vh;
    }

    @media (min-width:2500px){
        padding:0 20%;
        height:40vh;
    }

    @media only screen and (min-width: 1600px) {
        padding:0 10%;
    }

    @media (max-width:900px){
        height:130vh;
        background-size:cover;
    }

    @media (max-width:1300px){
        height:130vh;
        background-size:fill;
    }

    @media (min-width:700px){
        padding: 0 10%;
    }

    @media (min-width:1100px){
        padding: 0;
    }
`;

export const HeroMain = styled.div`
    position:relative;
`

export const CenterHero = styled.div`
    background: transparent;
    display:flex;
    align-items:center;

    > div {
        padding:6rem 15rem 10rem 15rem;
        line-height:0px;
        text-align:center;
        color: #fff;

        @media (max-width:900px){
            padding:5rem 5% 2rem 5%;
        }
    }

    @media (min-width:1500px){
        > div {
            padding: 10rem 15rem 10rem 15rem;
        }
    }
`

export const TextHeader = styled.h1`
    font-family: var(--generic-font);
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 57px;

    > span {
        color:#FFAB01;
        opacity:0.9;
        font-weight: 700;
        font-family: var(--main-font);
    }

    @media (max-width:900px){
        font-size:25px;
        line-height:45px;
    }
`

export const SupplText = styled.p`
    font-weight: 300;
    font-size: 18px;
    line-height: 40px;

    @media (max-width:900px){
        font-size:16px;
        line-height:40px;
    }
`

export const PointDot = styled.div`
    width:0.9rem;
    height:0.9rem;
    border:1px solid var(--text-black);
    border-radius:5rem;
    display:flex;
    align-items:Center;
    justify-content:Center;

    > div {
        width:0.5rem;
        height:0.5rem;
        border-radius:5rem;
        background: var(--text-black);
    }
`

export const HangingSection = styled.div`
    position:absolute;
    bottom: -12rem;
    left:50%;
    transform: translate(-50%, 0);
    width:60%;
    margin:0 auto;
    background: var(--main-bg);
    box-shadow: 0px 4px 32px rgba(0, 106, 255, 0.1);
    border-radius: 30px;
    display:flex;
    align-items:Center;
    justify-content:space-between;
    padding:0.3rem;

    > img {
        width:18rem;
    }

    > form {
        width:60%;
        padding:0 2rem 0 2rem;

        > h3 {
            color: #494949;
            font-weight:500;
        }

        > p {
            font-weight: 300;
            font-size: 14px;
            line-height: 30px;
            color: #5D5B5B;
        }
    }

    @media (min-width:900px){
        width:80%;
    }

    @media (min-width:1300px){
        width:60%;
    }

    @media (max-width:900px){
        width:90%;
        flex-direction:column;
        position:relative;
        bottom:-5rem;
        left:0;
        transform:none;

        > img {
            width:100%;
        }

        > form {
            width:94%;
            padding:0 3% 2rem 3%;
        }
    } 
    
    @media (max-width:900px){
        bottom: -1rem;
    }
`

export const TextField = styled.div`
    padding: 0.7rem;
    background: var(--main-bg);
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
    border-radius: 6px;
    display:flex;
    align-items:center;
    justify-content:space-between;

    > input {
        width:16rem;
    }

    @media (max-width:900px){
        padding:0.7rem 3%;

        > input {
            width:50%;
        }
    }
`